<template>
    <div>
        <b-card class="mt-1">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <b-card-text>
                <h3><strong>{{ $t('offering.title.base_info') }}</strong></h3>
                <table id="info_table" class="table table-striped">
                    <thead>
                    <tr>
                        <th>{{ form.offering.name }}</th>
                        <th>{{ $t('offering.info.account_info') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{{ $t('offering.info.date') }}: {{
                                form.offering.created_at  | formatDate('DD.MM.YYYY HH:mm:ss')
                            }}
                        </td>
                        <td>
                            <div>{{ $t('offering.info.name') }}: {{ form.offering.contact_person.vorname }}
                                {{ form.offering.contact_person.name }}
                            </div>
                            <div>{{ $t('offering.info.email') }}: {{ form.offering.contact_person.email }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $t('offering.info.status') }}:
                            <template v-if="form.offering.project_status">
                                {{ form.offering.project_status.name }}
                            </template>
                            <template v-else>
                                {{ form.offering.status }}
                            </template>
                        </td>
                        <td>
                            <template v-if="form.documents.shipping_label">
                                <a @click.prevent="$root.downloadDocument(form.documents.shipping_label.name)"
                                   :href="$root.getDocumentUrl(form.documents.shipping_label.name)"
                                >{{ form.documents.shipping_label.name }}</a>
                            </template>
                        </td>
                    </tr>
                    <tr v-if="form.offering.store">
                        <td>{{ $t('offering.info.store') }}: {{ form.offering.store.name }}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>{{ $t('offering.info.id') }}: {{ form.offering.id }}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>{{ $t('offering.info.project_name') }}</td>
                        <td>
                            <zw-input-group name="project_name"
                                            disable-label
                                            size="sm"
                                            v-model="form.offering.project_production.name"
                            >
                                <template #append-slot>
                                    <b-input-group-append>
                                        <b-button size="sm" @click="saveProductionName">
                                            <font-awesome-icon icon="save"/>
                                        </b-button>
                                    </b-input-group-append>
                                </template>
                            </zw-input-group>
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $t('offering.info.offering_name') }}</td>
                        <td>
                            <zw-input-group name="offering_name"
                                            disable-label
                                            size="sm"
                                            v-model="form.offering.name"
                            >
                                <template #append-slot>
                                    <b-input-group-append>
                                        <b-button size="sm" @click="saveOfferingName">
                                            <font-awesome-icon icon="save"/>
                                        </b-button>
                                    </b-input-group-append>
                                </template>
                            </zw-input-group>
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $t('offering.info.payment_synced') }}: <span v-if="form.payment_synced">{{
                                form.payment_synced.updated_at | formatDate('DD.MM.YYYY HH:mm:ss')
                            }}</span></td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
            </b-card-text>
        </b-card>

        <b-card class="mt-1">
            <b-card-text>
                <h3><strong>{{ $t('offering.tab.notices') }}</strong></h3>
                <offering-notices :offering_id="form.offering.id"/>
            </b-card-text>
        </b-card>

        <b-card class="mt-1">
            <b-card-text>
                <h3><strong>{{ $t('offering.title.api_logs') }}</strong></h3>
                <b-alert variant="info" show>
                    <font-awesome-icon icon="info-circle" class="mr-2"></font-awesome-icon>
                    {{ $t('offering.api_logs.info') }}
                </b-alert>

                <json-viewer v-if="!loading" :value="getApiLog()"></json-viewer>
            </b-card-text>
        </b-card>

        <b-card class="mt-1">
            <b-card-text>
                <h3><strong>{{ $t('jobs.title.jobs') }}</strong></h3>

                <zw-table ref="table"
                          tableName="offering_jobs"
                          columnsPrefix="jobs.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          title="jobs.title.jobs"
                          :sticky="false"
                          sticky-header="100%"
                >
                    <template #cell(status)="row">
                        <CBadge
                            @click="$root.$children[0].openModal('job-info-modal', {job_group: row.item.group, job_id: row.item.id})"
                            style="cursor: pointer;"
                            :color="statusClass(row.item.status)">{{ row.item.status }}
                        </CBadge>
                    </template>
                    <template #cell(id)="row">
                        <a @click="$root.$children[0].openModal('job-info-modal', {job_group: row.item.group, job_id: row.item.id})"
                           href="#">
                            {{ row.item.id }}
                        </a>
                    </template>
                    <template #cell(actions)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>
                            <b-dropdown-item
                                @click="$root.$children[0].openModal('job-info-modal', {job_group: row.item.group, job_id: row.item.id})">
                                {{ $t('jobs.title.job_info') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>

                </zw-table>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import commonTable from "@/bundles/erika_common_bundle/mixins/common-table";
import OfferingNotices from "@/modals/offering/offering-blocks/OfferingNotices";

export default {
    name: 'OfferingInfo',
    components: {OfferingNotices},
    mixins: [commonSave, commonTable],
    props: {
        'form': {
            type: [Object],
            required: true
        },
    },
    data() {
        return {
            loading: true,
            saveFilters: false,
        }
    },
    methods: {
        ...mapGetters('ApiLogs', ['getApiLog']),
        ...mapGetters('Jobs', ['getOfferingJobsTable']),
        shown() {
            this.loading = true
            const apiLog = this.$store.dispatch('ApiLogs/fetchApiLog', this.form.offering.id)
            Promise.all([apiLog])
                .then(() => {

                })
                .finally(() => {
                    this.loading = false
                })
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Jobs/fetchOfferingJobs', 'getOfferingJobsTable');
        },
        ctxAdditionalParams() {
            return {
                'offering_id': this.form.offering.id
            }
        },
        statusClass(status) {
            if (status == 'queued') {
                return 'info';
            } else if (status == 'handled') {
                return 'primary'
            } else if (status == 'done') {
                return 'success'
            } else if (status == 'failed') {
                return 'warning'
            }
        },
        saveProductionName() {
            this.loading = true
            this.$store.dispatch('Offering/updateOffering', {
                id: this.form.offering.id,
                project_production_name: this.form.offering.project_production.name,
            })
                .finally(() => {
                    this.loading = false
                })
        },
        saveOfferingName() {
            this.loading = true
            this.$store.dispatch('Offering/updateOffering', {
                id: this.form.offering.id,
                name: this.form.offering.name,
            })
                .finally(() => {
                    this.loading = false
                })
        },
    },
    mounted() {
        this.shown()
    }
}
</script>